import axios from "axios";
import {
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_INFO_FAIL,
  EDIT_USER_INFO_REQUEST,
  EDIT_USER_INFO_SUCCESS,
  GET_REF_LOGS_FAIL,
  GET_REF_LOGS_REQUEST,
  GET_REF_LOGS_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
} from "../constants/userConstants";
import { BASE_URL } from "../constants/constants";

export const getUserInfo = () => {
  return async (dispatch, getState) => {
    const {
      UserLoginReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: GET_USER_INFO_REQUEST });
    try {
      const { data } = await axios.get(`${BASE_URL}/api/user/info`, config);

      dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_USER_INFO_FAIL,
        payload: [error.response.data.errors],
      });
    }
  };
};

export const editUserInfo = (info) => {
  return async (dispatch, getState) => {
    const {
      UserLoginReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: EDIT_USER_INFO_REQUEST });
    try {
      const { data } = await axios.put(
        `${BASE_URL}/api/user/edit`,
        info,
        config
      );

      dispatch({
        type: EDIT_USER_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: EDIT_USER_INFO_FAIL,
        payload: [error.response.data],
      });
    }
  };
};

export const changePassword = (info) => {
  return async (dispatch, getState) => {
    const {
      UserLoginReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    try {
      const { data } = await axios.put(
        `${BASE_URL}/api/user/change-password`,
        info,
        config
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: [error.response.data],
      });
    }
  };
};


export const getRefLogs = () => {
  return async (dispatch, getState) => {
    const {
      UserLoginReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: GET_REF_LOGS_REQUEST });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/user/reflogs`,
        config
      );

      dispatch({
        type: GET_REF_LOGS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_REF_LOGS_FAIL,
        payload: [error.response.data],
      });
    }
  };
};
