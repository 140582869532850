import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProLayout } from "@ant-design/pro-components";
import { Dropdown } from "antd";
import Icon, { LogoutOutlined } from "@ant-design/icons";
import { RiShieldUserFill } from "react-icons/ri";

//Components
import { sidebar } from "./sidebar";
import { webRoutes } from "../../routes/web";
import { USER_LOGOUT } from "../../redux/constants/authConstants";
import { getUserInfo } from "../../redux/methods/userMethods";

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.GetUserInfoReducer);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const defaultProps = {
    title: (
      <span className="font-poppins font-semibold">
        Earning <span className="text-primary">&</span> Learning
      </span>
    ),
    fixedHeader: true,
    fixSiderbar: true,
    layout: "mix",
    logo: null,
    route: {
      routes: sidebar,
    },
  };

  //Functions
  const handleLogout = async () => {
    dispatch({ type: USER_LOGOUT });
    navigate(webRoutes.login);
    window.location.reload();
  };

  return (
    <div className="h-screen">
      <ProLayout
        {...defaultProps}
        token={{
          sider: {
            colorMenuBackground: "#202434",
            colorBgMenuItemSelected: "white",
            colorTextMenuSelected: "black",
            colorTextSubMenuSelected: "black",
            colorTextMenu: "white",
            colorTextMenuItemHover: "white",
          },
        }}
        location={location}
        onMenuHeaderClick={() => navigate(webRoutes.dashboard)}
        menuItemRender={(item, dom) => (
          <a
            onClick={(e) => {
              e.preventDefault();
              item.path && navigate(item.path);
            }}
            href={item.path}
          >
            {dom}
          </a>
        )}
        avatarProps={{
          icon: <Icon component={RiShieldUserFill} />,
          className: "bg-primary bg-opacity-20 text-primary text-opacity-90",
          size: "large",
          shape: "circle",
          title: `${user.firstname} ${user.lastname}`,
          render: (_, dom) => {
            return (
              <>
                <Link
                  to="https://www.tiktok.com/@akprofessor9?_t=8pPqjFPGqnC&_r=1"
                  target="_blank"
                  rel="noreferrer"
                  className="relative flex h-9 w-9 mr-2 items-center justify-center rounded-full border-[0.5px] border-stroke bg-primary "
                >
                  <svg
                    width="48px"
                    height="48px"
                    viewBox="0 0 48 48"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Tiktok</title>
                    <g
                      id="Icon/Social/tiktok-white"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <path
                        d="M38.0766847,15.8542954 C36.0693906,15.7935177 34.2504839,14.8341149 32.8791434,13.5466056 C32.1316475,12.8317108 31.540171,11.9694126 31.1415066,11.0151329 C30.7426093,10.0603874 30.5453728,9.03391952 30.5619062,8 L24.9731521,8 L24.9731521,28.8295196 C24.9731521,32.3434487 22.8773693,34.4182737 20.2765028,34.4182737 C19.6505623,34.4320127 19.0283477,34.3209362 18.4461858,34.0908659 C17.8640239,33.8612612 17.3337909,33.5175528 16.8862248,33.0797671 C16.4386588,32.6422142 16.0833071,32.1196657 15.8404292,31.5426268 C15.5977841,30.9658208 15.4727358,30.3459348 15.4727358,29.7202272 C15.4727358,29.0940539 15.5977841,28.4746337 15.8404292,27.8978277 C16.0833071,27.3207888 16.4386588,26.7980074 16.8862248,26.3604545 C17.3337909,25.9229017 17.8640239,25.5791933 18.4461858,25.3491229 C19.0283477,25.1192854 19.6505623,25.0084418 20.2765028,25.0219479 C20.7939283,25.0263724 21.3069293,25.1167239 21.794781,25.2902081 L21.794781,19.5985278 C21.2957518,19.4900128 20.7869423,19.436221 20.2765028,19.4380839 C18.2431278,19.4392483 16.2560928,20.0426009 14.5659604,21.1729264 C12.875828,22.303019 11.5587449,23.9090873 10.7814424,25.7878401 C10.003907,27.666593 9.80084889,29.7339663 10.1981162,31.7275214 C10.5953834,33.7217752 11.5748126,35.5530237 13.0129853,36.9904978 C14.4509252,38.4277391 16.2828722,39.4064696 18.277126,39.8028054 C20.2711469,40.1991413 22.3382874,39.9951517 24.2163416,39.2169177 C26.0948616,38.4384508 27.7002312,37.1209021 28.8296253,35.4300711 C29.9592522,33.7397058 30.5619062,31.7522051 30.5619062,29.7188301 L30.5619062,18.8324027 C32.7275484,20.3418321 35.3149087,21.0404263 38.0766847,21.0867664 L38.0766847,15.8542954 Z"
                        id="Fill-1"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </svg>
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=61565169005622&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  className="relative flex h-9 w-9 mr-2 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray-100 text-primary"
                >
                  <svg
                    className="fill-current text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="#000000"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.03998C6.5 2.03998 2 6.52998 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.84998C10.44 7.33998 11.93 5.95998 14.22 5.95998C15.31 5.95998 16.45 6.14998 16.45 6.14998V8.61998H15.19C13.95 8.61998 13.56 9.38998 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5878 18.0622 20.3855 19.6099 18.57C21.1576 16.7546 22.0054 14.4456 22 12.06C22 6.52998 17.5 2.03998 12 2.03998Z" />
                  </svg>
                </Link>
                <Link
                  to="https://www.instagram.com/ahmad_khan9111?igsh=MThtdXkwZmNhNmZ0ZA=="
                  target="_blank"
                  className="relative flex h-9 w-9 mr-2 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray-100 text-primary"
                >
                  <svg
                    className="fill-current text-primary"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6,2h20c2.2,0,4,1.8,4,4v20c0,2.2-1.8,4-4,4H6c-2.2,0-4-1.8-4-4V6C2,3.8,3.8,2,6,2z" />
                    <g>
                      <path
                        fill="#FFFFFF"
                        d="M21.3,9.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2C22.4,10.2,21.9,9.7,21.3,9.7z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M16,11.2c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9C21,13.4,18.8,11.2,16,11.2z M16,19.3   c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2C19.2,17.9,17.8,19.3,16,19.3z"
                      />
                      <path
                        fill="#FFFFFF"
                        d="M20,6h-8c-3.3,0-6,2.7-6,6v8c0,3.3,2.7,6,6,6h8c3.3,0,6-2.7,6-6v-8C26,8.7,23.3,6,20,6z M24.1,20   c0,2.3-1.9,4.1-4.1,4.1h-8c-2.3,0-4.1-1.9-4.1-4.1v-8c0-2.3,1.9-4.1,4.1-4.1h8c2.3,0,4.1,1.9,4.1,4.1V20z"
                      />
                    </g>
                  </svg>
                </Link>
                <Link
                  to="https://youtube.com/@akprofessor.?si=QtLG_kUN8TmuwVYY"
                  target="_blank"
                  className="relative flex h-9 w-9 mr-2 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray-100 text-primary"
                >
                  <svg
                    className="fill-current text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="#000000"
                    viewBox="-143 145 512 512"
                  >
                    <polygon points="78.9,450.3 162.7,401.1 78.9,351.9 	" />

                    <path
                      d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M241,446.8L241,446.8
		              c0,44.1-44.1,44.1-44.1,44.1H29.1c-44.1,0-44.1-44.1-44.1-44.1v-91.5c0-44.1,44.1-44.1,44.1-44.1h167.8c44.1,0,44.1,44.1,44.1,44.1
		              V446.8z"
                    />
                  </svg>
                </Link>
                <Link
                  to={webRoutes.notifications}
                  className="relative flex h-9 w-9 mr-2 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray-100 text-primary"
                >
                  <span
                    className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1 inline`}
                  >
                    <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
                  </span>
                  {!user.isNotifyRead && (
                    <span className="absolute bottom-0 left-0 w-2 h-2 bg-orange-400 rounded-full"></span>
                  )}
                  <svg
                    className="fill-current duration-300 ease-in-out"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.1999 14.9343L15.6374 14.0624C15.5249 13.8937 15.4687 13.7249 15.4687 13.528V7.67803C15.4687 6.01865 14.7655 4.47178 13.4718 3.31865C12.4312 2.39053 11.0812 1.7999 9.64678 1.6874V1.1249C9.64678 0.787402 9.36553 0.478027 8.9999 0.478027C8.6624 0.478027 8.35303 0.759277 8.35303 1.1249V1.65928C8.29678 1.65928 8.24053 1.65928 8.18428 1.6874C4.92178 2.05303 2.4749 4.66865 2.4749 7.79053V13.528C2.44678 13.8093 2.39053 13.9499 2.33428 14.0343L1.7999 14.9343C1.63115 15.2155 1.63115 15.553 1.7999 15.8343C1.96865 16.0874 2.2499 16.2562 2.55928 16.2562H8.38115V16.8749C8.38115 17.2124 8.6624 17.5218 9.02803 17.5218C9.36553 17.5218 9.6749 17.2405 9.6749 16.8749V16.2562H15.4687C15.778 16.2562 16.0593 16.0874 16.228 15.8343C16.3968 15.553 16.3968 15.2155 16.1999 14.9343ZM3.23428 14.9905L3.43115 14.653C3.5999 14.3718 3.68428 14.0343 3.74053 13.6405V7.79053C3.74053 5.31553 5.70928 3.23428 8.3249 2.95303C9.92803 2.78428 11.503 3.2624 12.6562 4.2749C13.6687 5.1749 14.2312 6.38428 14.2312 7.67803V13.528C14.2312 13.9499 14.3437 14.3437 14.5968 14.7374L14.7655 14.9905H3.23428Z"
                      fill=""
                    />
                  </svg>
                </Link>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "logout",
                        icon: <LogoutOutlined />,
                        label: "Logout",
                        onClick: () => {
                          handleLogout();
                        },
                      },
                    ],
                  }}
                >
                  {dom}
                </Dropdown>
              </>
            );
          },
        }}
      >
        <Outlet />
      </ProLayout>
    </div>
  );
}
